import React from "react";
import LocalizedStrings from "react-localization";
import localeFile from "../../local/content";
import { connect } from "react-redux";

const strings = new LocalizedStrings(localeFile);

const Features = (props) => {
    strings.setLanguage(props.languageCode);

    return (
        <div className="container cabout ptb-80">
            <div className="section-title">
                <h2>
                   {strings.strategy}
                </h2>
             
            </div>
            <section className="why-choose-us-tow ">
                <div className=" why-choose-us-cent-tow  container">
                    <div>
                        <div className="">
                            <div className="single-box-tow">
                                <div className="content-tow">
                                    <div className="icon-tow mx-2">
                                        <i class="icofont-eye-alt"></i>
                                    </div>
                                    <div className="">
                                        <h3>{strings.NVision} </h3>
                                        <p>{strings.vision}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                            <div className="single-box-tow">
                                <div className="content-tow">
                                    <div className="icon-tow mx-2">
                                        <i class="icofont-brand-slideshare"></i>
                                    </div>
                                    <div className="">
                                        <h3>{strings.NSlogan}</h3>
                                        <p>{strings.slogan} </p>
                                    </div>
                                </div>
                            </div>
                    </div>

                    <div className="">
                        <div className="single-box-tow">
                            <div className="content-tow">
                                <div className="icon-tow mx-2">
                                    <i class="icofont-dart"></i>
                                </div>{" "}
                                <div className="">
                                    <h3>{strings.NMission}</h3>
                                    <p>{strings.mission}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(Features);

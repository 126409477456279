import React, { useState, useEffect } from "react";
import lax from "lax.js";
import axios from "../../api/axios";
import LocalizedStrings from "react-localization";
import localeFile from "../../local/content";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel3";
import PlatinumSponsors from "../LaxButton/PlatinumSponsors";
import GoldSponsors from "../LaxButton/GoldSponsors";

import ImageWithPlayIcon from "../Shared/ImageWithPlayIcon";
import { Link } from "react-router-dom";

const strings = new LocalizedStrings(localeFile);

const options = {
    nav: false,
    dots: true,
    margin: 8,
    startPosition: 1,
    autoplay: true,
    navText: [
        "<i class='icofont-rounded-left'></i>",
        "<i class='icofont-rounded-right'></i>",
    ],
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        576: {
            items: 1,
        },
        768: {
            items: 1,
        },
        1200: {
            items: 1,
        },
    },
};
const About = (props) => {
    const [posts, setPosts] = useState([]);
    const [dataVideo, setDataVideo] = useState([]);
    const [dataImage, setDataImage] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [url, setUrl] = useState("");
    useEffect(() => {
        axios
            .get(`get_about`)
            .then((res) => {
                const aboutData = res.data.about[0];
                setPosts(aboutData);
                if (aboutData) {
                    setDataImage(aboutData.about_images);
                }
                setDataVideo(aboutData.about_videos);
            })
            .catch(({ response }) => {});
    }, []);

    useEffect(() => {
        lax.setup();

        const handleScroll = () => {
            lax.update(window.scrollY);
        };

        document.addEventListener("scroll", handleScroll, false);

        return () => {
            document.removeEventListener("scroll", handleScroll, false);
        };
    }, []);

    useEffect(() => {
        lax.setup();

        const handleScroll = () => {
            lax.update(window.scrollY);
        };

        document.addEventListener("scroll", handleScroll, false);

        axios.get("/get_about").then((res) => {
            const aboutData = res.data.about[0];
            setPosts(aboutData);
        });

        return () => {
            document.removeEventListener("scroll", handleScroll);
        };
    }, []);

    strings.setLanguage(props.languageCode);

    return (
        <section className="about-area-two ptb-120 bg-image">
            <div className="container">
                <div className="row h-100 align-items-center">
                    <div className="col-lg-6">
                        <div className="about-content">
                            <h2>
                                <b>-</b>{" "}
                                {strings.hreflangCode === "fr"
                                    ? posts.presentation_title
                                    : strings.hreflangCode === "ar"
                                    ? posts.presentation_title_arabe
                                    : posts.presentation_title_eng}
                            </h2>
                            <p>
                                {strings.hreflangCode === "fr"
                                    ? posts.presentation
                                    : strings.hreflangCode === "ar"
                                    ? posts.presentation_arabe
                                    : posts.presentation_eng}
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="about-image">
                            <div className="container cabout ptb-80">
                                <div className="container">
                                    <div className="row">
                                        <OwlCarousel
                                            className="carsoul"
                                            {...options}
                                        >
                                            {dataImage.length !== 0 &&
                                                dataImage.map((imag, index) => (
                                                    <div
                                                        className="col-lg-12 col-md-12"
                                                        key={index}
                                                    >
                                                        <div className="m-3">
                                                            <img
                                                                className="img"
                                                                src={`https://dashboardawledna.futurevisions.tn/${imag.image}`}
                                                                alt="Partner Logo"
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                        </OwlCarousel>

                                        <div className="container-fluid">
                                            <OwlCarousel
                                                className="carsoul"
                                                {...options}
                                            >
                                                {dataVideo.length !== 0 &&
                                                    dataVideo.map(
                                                        (video, index) => (
                                                            <div
                                                                className="col-lg-12 col-md-12"
                                                                key={index}
                                                            >
                                                                <div className="author-vd m-3">
                                                                    <Link
                                                                        to="#"
                                                                        className="img"
                                                                        onClick={() => {
                                                                            setIsOpen(
                                                                                true
                                                                            );
                                                                            setUrl(
                                                                                video.url
                                                                            );
                                                                        }}
                                                                    >
                                                                        <ImageWithPlayIcon
                                                                            src={`http://127.0.0.1:8000/${video.image}`}
                                                                        />
                                                                        <h4 className="widget-title text-center">
                                                                            {
                                                                                video.title
                                                                            }
                                                                        </h4>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                            </OwlCarousel>
                                        </div>
                                    </div>
                                </div>
                            </div>{" "}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(About);

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import axios from "../../api/axios";
import SimpleDateTime from "react-simple-timestamp-to-date";
import LocalizedStrings from "react-localization";
import localeFile from "../../local/content";
import { connect } from "react-redux";

const strings = new LocalizedStrings(localeFile);

function BlogOne(props) {
    const [categories, setCategoories] = useState([]);
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.currentTime = 30; // Réglez la position de lecture à 50 secondes
            videoRef.current.play(); // Démarrez la lecture
        }
    }, []);
    useEffect(() => {
        async function fetchCategories() {
            const response = await axios.get(`get_categories`);
            const categories = response.data.category;
            setCategoories(categories.flatMap((category) => category));
        }

        fetchCategories();
    }, []);

    strings.setLanguage(props.languageCode);

    return (
        <React.Fragment>
            {/* <div className="  blogs-page">
                <div className="container">
                    <h1>{strings.ACTUALITÉS}</h1>
                    <span>{strings.Nos_dernières_nouvelles}</span>
                </div>
            </div> */}

            <section className="blog-area ptb-120 ">
                <div className="container-cards container">
                    {categories.length != 0 &&
                        categories.map((category, index) => {
                            return (
                                <div key={index}>
                                    {category.posts.length != 0 &&
                                        category.posts.map((post, index) => {
                                            return (
                                                <div class="card">
                                                    <div class="card__header">
                                                        {post.post_images
                                                            .length > 0 &&
                                                            post.post_images.map(
                                                                (im, index) => {
                                                                    return (
                                                                        <img
                                                                            key={
                                                                                index
                                                                            }
                                                                            src={
                                                                                im.presentation ==
                                                                                "1"
                                                                                    ? "https://dashboard.futurevisions.tn/" +
                                                                                      im.image
                                                                                    : ""
                                                                            }
                                                                        />
                                                                    );
                                                                }
                                                            )}
                                                    </div>
                                                    <div class="card__body">
                                                        <span class="tag tag-red">
                                                            {strings.hreflangCode ==
                                                            "fr"
                                                                ? category.name
                                                                : strings.hreflangCode ==
                                                                  "ar"
                                                                ? category.name_arabe
                                                                : category.name_eng}
                                                        </span>
                                                        <h4 >
                                                            {" "}
                                                            <Link
                                                            style={{color:"black"}}
                                                                to={`/single-blog/id=${post.id}`}
                                                            >
                                                                {strings.hreflangCode ==
                                                                "fr"
                                                                    ? post.titre
                                                                    : strings.hreflangCode ==
                                                                      "ar"
                                                                    ? post.titre_arabe
                                                                    : post.titre_eng}
                                                            </Link>
                                                        </h4>
                                                        <div className="card-description">
                                                        <p>
                                                            {strings.hreflangCode ==
                                                            "fr"
                                                                ? post.mini_description
                                                                : strings.hreflangCode ==
                                                                  "ar"
                                                                ? post.mini_description_arabe
                                                                : post.mini_description_eng}
                                                        </p></div>
                                                    </div>
                                                    <div class="card__footer">
                                                        <div class="user">
                                                            <div class="user__info">
                                                                <small>
                                                                    {" "}
                                                                    <SimpleDateTime
                                                                        dateFormat="DMY"
                                                                        dateSeparator="/"
                                                                        timeSeparator=":"
                                                                    >
                                                                        {
                                                                            post.updated_at
                                                                        }
                                                                    </SimpleDateTime>
                                                                </small>

                                                                <Link
                                                                    to={`/single-blog/id=${post.id}`}
                                                                    className="read-more-btn"
                                                                >
                                                                    {
                                                                        strings.En_savoir_plus
                                                                    }
                                                                    {strings.hreflangCode !=
                                                                    "ar" ? (
                                                                        <i className="icofont-double-right">
                                                                            {" "}
                                                                        </i>
                                                                    ) : (
                                                                        <i className="icofont-double-left">
                                                                            {" "}
                                                                        </i>
                                                                    )}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            );
                        })}
                </div>
            </section>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(BlogOne);

import React, { useState, useEffect } from "react";
import ModalVideo from "react-modal-video";
import axios from "../../api/axios";
import LocalizedStrings from "react-localization";
import localeFile from "../../local/content";
import { connect } from "react-redux";
import hero from "../../assets/images/social-integration-working-team.jpg";
import logo from "../../assets/images/359674975_259010383191530_6932926900808888644_n.jpg";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faProjectDiagram, faUsers, faHandshake } from "@fortawesome/free-solid-svg-icons";
const strings = new LocalizedStrings(localeFile);

const MainBanner = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [posts, setPosts] = useState([]);

    const openModal = () => {
        setIsOpen(true);
    };

    useEffect(() => {
        axios.get(`get_about`).then((res) => {
            const fetchedPosts = res.data.about[0];
            setPosts(fetchedPosts);
        });
    }, []);

    strings.setLanguage(props.languageCode);

    return (
        <React.Fragment>
            {/* <div className="main-banner  ">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                <div className="main-banner-content banner-content-center">
                                    <h1>
                                        <span>
                                            {strings.hreflangCode === "fr"
                                                ? posts.big_title
                                                : strings.hreflangCode === "ar"
                                                ? posts.big_title_arabe
                                                : posts.big_title_eng}
                                        </span>
                                    </h1>

                                    <div className="button-box">
                                        <p className="pt-5">
                                            {" "}
                                            {strings.firstDesc}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="main-banner-content ">
                                    <div className="imag-landing-container">
                                        <img
                                            src={image}
                                            className="imag-landing"
                                        />
                                     
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <header id="home" className="container-fluid main-banner item-bg2" style={{backgroundColor:"#f9f9f9"}}>
            <div className="container">
            <div class="hero-container">
                    <div class="text-container">
                        <div className="text">
                        <h2>
                            {strings.hreflangCode === "fr"
                                ? posts.big_title
                                : strings.hreflangCode === "ar"
                                ? posts.big_title_arabe
                                : posts.big_title_eng}
                        </h2>
                        <p>
                            Vestibulum ac diam sit amet quam vehicula elementum
                            <br /> amet est on dui. Nulla porttitor accumsan
                            tincidunt.
                        </p>
                       
                        <div class="hero-btns">
                        <NavLink
                                    to="/about-2"
                                   
                                >
                                    {strings.a_PROPOS_DE_NOUS}
                                </NavLink>
                           
                        </div></div>
                    </div>
                    <div className="circle-content">
                    <div className="circle-container"> 
      <div className="rotating-circle">
        <div className="circle-item item1">
            <div className="item-icon">
          <FontAwesomeIcon icon={faProjectDiagram} className="icons"/></div>
          <span className="count"><span>1000</span></span>
          <span className="description">{strings.youthBen}</span>
        </div>
        <div className="circle-item item2">
        <div className="item-icon">
          <FontAwesomeIcon icon={faUsers}  className="icons"/>   </div>
          <span className="count"><span>+50</span></span>
          <span className="description">{strings.projetsBen}</span>
        </div>
        <div className="circle-item item3">
        <div className="item-icon">
          <FontAwesomeIcon icon={faHandshake} className="icons" />   </div>
          <span className="count"><span>100</span></span>
          <span className="description">{strings.partnersBen}</span>
       
      </div>   </div>
    </div> </div>

  
                </div>
            
            </div>
            </header>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(MainBanner);

import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import LocalizedStrings from "react-localization";
import localeFile from "../../local/content";
import axios from "../../api/axios";
import Navigation from "../Navigation/Navigation";

import { connect } from "react-redux";
import  logo  from "../../assets/images/LOGO FUTURE VISIONS 2023-25.jpg";
import NavBarfooter from "../Navigation/Navigationfoooter";

const Footer = (props) => {
    const strings = new LocalizedStrings(localeFile);
    const [social, setSocial] = useState({});

    useEffect(() => {
        axios.get(`/getSocial`).then((res) => {
            const socialData = res.data.social[0];
            setSocial(socialData);
        });
    }, []);

    strings.setLanguage(props.languageCode);

    return (
        <div className="footer-area">
            <div className="copyright-area ">
     
                 
                <div className=" text-center">
            
                    <p>
                        Copyright <i className="icofont-copyright"></i> 2024{" "}
                        {strings.FutureVisions}.{" "}
                        {strings.All_rights_reserved}
                    </p>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(Footer);

import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import { connect } from 'react-redux';

const strings = new LocalizedStrings(localeFile);

const MainBanner = (props) => {
    strings.setLanguage(props.languageCode);
    const videoRef = useRef(null);

    useEffect(() => {
      if (videoRef.current) {
        videoRef.current.currentTime = 13; // Réglez la position de lecture à 50 secondes
        videoRef.current.play(); // Démarrez la lecture
      }
    }, []);   
    return (
        <div className="page-title-area  about item-bg2">
            <div className="container">
            <video preload="auto" loading="lazy" autoPlay loop muted className="video-background" ref={videoRef}>
            <source src="https://d2t3k1w51fob7.cloudfront.net/S%C3%A9quence%2003.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                    </video>
                <h1> {strings.a_propos}</h1>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode
    };
};

export default connect(mapStateToProps)(MainBanner);

import MainBanner from '../HomeTwo/MainBanner';
import About from '../HomeTwo/About';

import Organisations from '../HomeTwo/Organisations';
import LatesNews from '../HomeTwo/LatestNews';

import GoTop from '../Shared/GoTop';
import React, { useEffect } from 'react'
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import {connect} from 'react-redux';
import Calender from '../schedule/Calender';
import CalenderArabe from '../schedule/CalendrierArabe';
import Partner from '../HomeTwo/partner';
import Features from '../containers/features/Features';
import Gallery from '../Common/Gallery';
import SwiperSection from '../HomeTwo/SwiperSection';


const strings = new LocalizedStrings(localeFile);


function HomeTwo(props) {
    strings.setLanguage(props.languageCode);

    useEffect(() => {
    
    }, [props.languageCode]);
  
    return (
      <React.Fragment>
        <MainBanner />
        <Features />
        <About />
        <Partner />
        <LatesNews />
    {/* <Slider/> */}
    {/* <Calendrier/> */}
        {/* <Gallery /> */}
        <Organisations />
        <Calender />
   
{/* <SwiperSection/> */}
        <GoTop scrollStepInPx="50" delayInMs="16.66" />
      </React.Fragment>
    );
  }
  
  const mapStateToProps = (state) => {
    return {
      languageCode: state.languageCode,
    };
  };
  
  export default connect(mapStateToProps)(HomeTwo);
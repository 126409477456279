import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../../api/axios";
import LocalizedStrings from "react-localization";
import localeFile from "../../local/content";
import { connect } from "react-redux";

const strings = new LocalizedStrings(localeFile);

const OrganisationsList = (props) => {
    const [associations, setAssociations] = useState([]);

    useEffect(() => {
        axios
            .get(`get_associations`)
            .then((res) => {
                const associations = res.data.Associations;
                setAssociations(associations);
            })
            .catch(({ response }) => {
                // En cas d'erreur, vous pouvez gérer l'erreur ici
            });
    }, []);

    strings.setLanguage(props.languageCode);

    return (
        <section className="speakers-area ptb-120">
            <div className="container">
                <div className="row">
                    {associations.map((association, index) => (
                        <div className="col-lg-4 col-md-6 " key={index}>
                            <a href={`/Organisation/id=${association.id}`}>
                                <div className="single-speakers-box mt-5">
                                    {association.associaition_images.map(
                                        (im, index) => (
                                            <div
                                                className="speakers-image"
                                                key={index}
                                            >
                                                <Link
                                                    to={`/Organisation/id=${association.id}`}
                                                >
                                                    <img
                                                        src={
                                                            im.presentation ==
                                                            "1"
                                                                ? `https://dashboardawledna.futurevisions.tn/${im.image}`
                                                                : ""
                                                        }
                                                        alt=""
                                                    />
                                                </Link>
                                            </div>
                                        )
                                    )}
                                    <div className="speakers-content">
                                        <h3>
                                            {strings.hreflangCode === "fr"
                                                ? association.nom
                                                : association.nom_arabe}
                                        </h3>
                                        <span>
                                            {strings.hreflangCode === "fr"
                                                ? association.ville
                                                : association.ville_arabe}
                                        </span>
                                        <ul className="social">
                                            <li>
                                                {association.Facebook !=
                                                null ? (
                                                    <a
                                                        href={
                                                            association.Facebook
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <i className="icofont-facebook"></i>
                                                    </a>
                                                ) : (
                                                    ""
                                                )}
                                            </li>
                                            <li>
                                                {association.Twitter != null ? (
                                                    <a
                                                        href={
                                                            association.Twitter
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <i className="icofont-twitter"></i>
                                                    </a>
                                                ) : (
                                                    ""
                                                )}
                                            </li>
                                            <li>
                                                {association.Instagram !=
                                                null ? (
                                                    <a
                                                        href={
                                                            association.Instagram
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <i className="icofont-instagram"></i>
                                                    </a>
                                                ) : (
                                                    ""
                                                )}
                                            </li>
                                            <li>
                                                {association.Linkedin !=
                                                null ? (
                                                    <a
                                                        href={
                                                            association.Linkedin
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <i className="icofont-linkedin"></i>
                                                    </a>
                                                ) : (
                                                    ""
                                                )}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(OrganisationsList);

import React, { useEffect, useRef, useState } from "react";
import lax from "lax.js";
import LaxButton from "../Shared/LaxButton";
import axios from "../../api/axios";
import LocalizedStrings from "react-localization";
import localeFile from "../../local/content";
import { connect } from "react-redux";

const strings = new LocalizedStrings(localeFile);

const About = (props) => {
    const [posts, setPosts] = useState({});

    useEffect(() => {
        lax.setup();

        const handleScroll = () => {
            lax.update(window.scrollY);
        };

        document.addEventListener("scroll", handleScroll, false);

        return () => {
            document.removeEventListener("scroll", handleScroll, false);
        };
    });
    const [social, setSocial] = useState({});

    useEffect(() => {
        axios.get(`/getSocial`).then((res) => {
            const socialData = res.data.social[0];
            setSocial(socialData);
        });
    }, []);

    useEffect(() => {
        axios.get(`get_about`).then((res) => {
            const aboutData = res.data.about[0];
            setPosts(aboutData);
        });
    }, []);
    const elementRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = elementRef.current;
            if (element) {
                const elementTop = element.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;

                // Check if the element is in the viewport
                if (elementTop < windowHeight) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            }
        };

        // Attach the scroll event listener
        window.addEventListener("scroll", handleScroll);

        // Initial check on component mount
        handleScroll();

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    strings.setLanguage(props.languageCode);

    return (
        <section className="about-area-two  container">
            <div className="container about-section">
                <div className="container about-section-title">
            <h2>
                          
                          {strings.a_PROPOS_DE_NOUS}
                      </h2>
                      <div className="about-content">
                          
                          <p>
                              {strings.hreflangCode === "fr"
                                  ? posts.presentation
                                  : strings.hreflangCode === "ar"
                                  ? posts.presentation_arabe
                                  : posts.presentation_eng}
                          </p>
                      </div>
                      <div className="d-flex justify-content-center mt-3 mb-3">
                            {social.Facebook != null ? (
                                <a className="social" href={social.Facebook}>
                                    <i className="icofont-facebook  custom-icon mx-2"></i>
                                </a>
                            ) : (
                                ""
                            )}
                            {social.Youtube != "" ? (
                                <a className="social" href={social.Youtube}>
                                    {" "}
                                    <i className="icofont-youtube  custom-icon mx-2"></i>
                                </a>
                            ) : (
                                ""
                            )}
                            {social.Linkedin != "" ? (
                                <a className="social" href={social.Linkedin}>
                                    {" "}
                                    <i className="icofont-linkedin  custom-icon mx-2"></i>
                                </a>
                            ) : (
                                ""
                            )}

                            {social.Twitter != "" ? (
                                <a className="social" href={social.Twitter}>
                                    {" "}
                                    <i className="icofont-twitter  custom-icon mx-2"></i>
                                </a>
                            ) : (
                                ""
                            )}
                            {social.Instagram != "" ? (
                                <a className="social" href={social.Instagram}>
                                    {" "}
                                    <i className="icofont-instagram  custom-icon mx-2"></i>{" "}
                                </a>
                            ) : (
                                ""
                            )}
                        </div>
                      <div className="line"></div>
                      <div className="mt-5 album-about">
                      <div class="TabImage">
                                <div class="img1">
                                    <figure>    <img
                                src={`https://dashboardawledna.futurevisions.tn/${posts.photo_presentation}`}
                                className="about-img1"
                                alt="about"
                            /></figure>	
                                </div>
                                <div class="img2">
                                    <figure>    <img
                                src={`https://dashboardawledna.futurevisions.tn/${posts.photo_presentation}`}
                                className="about-img1"
                                alt="about"
                            /></figure>
                                </div>
                            </div>
                      </div>
                      <div class="about-image">
                                    <img
                                src={`https://dashboardawledna.futurevisions.tn/${posts.photo_presentation}`}
                                className="about-img1"
                                alt="about"
                            />	
                                </div>
                      </div>
                    
         
         
            </div>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(About);


import React from 'react';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import { connect } from 'react-redux';
import TeamCard from './TeamCard';
import imageSeif from '../../assets/images/teams/seif.png';
import imageRania from '../../assets/images/teams/rania.jpg';
import imageAmel from '../../assets/images/teams/amel.jpg';
import imageLatifa from '../../assets/images/teams/latifa.jpg';
import imageYassine from '../../assets/images/teams/yassine.jpg';
import imageLamiss from '../../assets/images/teams/lamiss.JPG';

const strings = new LocalizedStrings(localeFile);

const Teams = (props) => {
  strings.setLanguage(props.languageCode);

  const teamMembers = [
    {
      name: strings.seif,
      profession:strings.president,
      image: imageSeif,
    },
    {
      name: strings.yassine,
      profession: strings.treasurer,
      image: imageYassine,
    },
    {
      name: strings.lamiss,
      profession: strings.secretary,
      image: imageLamiss,
    },
    {
      name: strings.latifa,
      profession: strings.financeRes,
      image: imageLatifa,
    },
    {
        name: strings.amel,
        profession: strings.coord,
        image: imageAmel,
      },
      {
        name: strings.rania,
        profession: strings.webdev,
        image: imageRania,
      },
  ];

  return (
    <section className="container cabout teams">
      <div className="section-title">
        <h2>
         {strings.Team}
        </h2>
     
      </div>
      <div className="team-list">
        {teamMembers.map((member, index) => (
          <div className="single-team" key={index}>
            <div className="team-image">
              <img src={member.image} alt={member.name} />
            </div>
            <div className="team-info">
              <h5>{member.name}</h5>
              <h6>{member.profession}</h6>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};

export default connect(mapStateToProps)(Teams);

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavBar from "./components/Navigation/Navigation";
import HomeTwo from "./components/pages/HomeTwo";
import AboutTwo from "./components/pages/AboutTwo";
import Organisations from "./components/pages/Organisations";
import ScheduleFour from "./components/pages/ScheduleFour";
import NotFound from "./components/pages/NotFound";
import BlogOne from "./components/pages/BlogOne";
import BlogDetails from "./components/pages/BlogDetails";
import Organisationitem from "./components/pages/Organisationitem";
import BlogSpecCategory from "./components/pages/BlogSpecCategory";
import LocalizedStrings from 'react-localization';
import localeFile from './local/content';
import Contact from "./components/pages/Contact";
import Footer from "./components/Common/Footer";

const AppRouter = () => {
    const strings = new LocalizedStrings(localeFile);

 
  
    return (
      
         
        <Router>
         
    <NavBar/>
            <Switch>
                <Route path="/" exact component={HomeTwo} />
                <Route path="/home" exact component={HomeTwo} />
               
                <Route path="/about-2" exact component={AboutTwo} />

                <Route path="/Organisations" exact component={Organisations} />
         
                {/* <Route path="/Ressources" exact component={ScheduleFour} /> */}
        
              
                <Route path="/blog-1" exact component={BlogOne} />
                <Route path="/blog/:id" exact component={BlogSpecCategory} />
                <Route path="/contact" exact component={Contact} />

             
                <Route path="/single-blog/:id" exact component={BlogDetails} />
                <Route path="/Organisation/:id" exact component={Organisationitem} />
                <Route path="/error-404" exact component={NotFound} />
                <Route component={NotFound} />
            </Switch>  
            <Footer />
        </Router>
        
    );
};

export default AppRouter;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import lax from "lax.js";
import axios from "../../api/axios";
import LocalizedStrings from "react-localization";
import localeFile from "../../local/content";
import OwlCarousel from "react-owl-carousel3";
import { connect } from "react-redux";

const strings = new LocalizedStrings(localeFile);

const Organisations = (props) => {
    const [associations, setAssociations] = useState([]);
    const [imagepresentation, setImagepresentation] = useState("");
    const options = {
        loop: true,
        autoplay: true,
        smartSpeed: 100,
      
        margin: 8,
        animateOut: true,
        animateIn: true,
        startPosition: 1,
        lazyLoad: true,
        navText: [
            "<i class='icofont-circled-left '></i>",
            "<i class='icofont-circled-right'></i>",
        ],
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            576: {
                items: 2,
            },
            768: {
                items: 2,
            },
            1200: {
                items: 3,
            },
        },
    };

    useEffect(() => {
        lax.setup();

        document.addEventListener(
            "scroll",
            function (x) {
                lax.update(window.scrollY);
            },
            false
        );

        axios
            .get(`gettow_associations`)
            .then((res) => {
                const fetchedAssociations = res.data.Associations;
                setAssociations(fetchedAssociations);
            })
            .catch(({ response }) => {});

        lax.update(window.scrollY);
    }, []);

    strings.setLanguage(props.languageCode);

    return (
        <section className="about-area-two  ptb-120 pb-0 bg-image ">
            <div className="container ">
            <div className="section-title about-section-title">
    <h2>{strings.Organisations_Members}</h2>
    <div className="bar-line">
        <span className="bar"></span>
    </div>
</div>
<div className="view-details">


                <Link to="/blog-1" className="btn  mt-3">
                        {strings.Voir_toutes_les_nouvelles}
                    </Link></div>

                <div className=" ">
                    <OwlCarousel
                        {...options}
                        className="platinum-partner-slides owl-carousel speakers-area owl-theme carsoul"
                        startPosition={1}
                    >
                        {associations.map((association, index) => (
                            <div className=" mx-3" key={index}>
                                {association.associaition_images.map(
                                    (im, index) => (
                                        <Link
                                            to={`/Organisation/id=${association.id}`}
                                        >
                                            <div className="single-speakers-box">
                                                <div
                                                    className="speakers-image"
                                                    key={index}
                                                >
                                                    <img
                                                        src={
                                                            im.presentation ==
                                                            "1"
                                                                ? `https://dashboardawledna.futurevisions.tn/${im.image}`
                                                                : ""
                                                        }
                                                        alt=""
                                                    />
                                                </div>

                                                <div className="speakers-content">
                                                 

                                                
                                                    <ul className="social">
                                                        <li>
                                                            {association.Facebook !=
                                                            null ? (
                                                                <a
                                                                    href={
                                                                        association.Facebook
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <i className="icofont-facebook"></i>
                                                                </a>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </li>
                                                        <li>
                                                            {association.Twitter !=
                                                            null ? (
                                                                <a
                                                                    href={
                                                                        association.Twitter
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <i className="icofont-twitter"></i>
                                                                </a>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </li>
                                                        <li>
                                                            {association.Instagram !=
                                                            null ? (
                                                                <a
                                                                    href={
                                                                        association.Instagram
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <i className="icofont-instagram"></i>
                                                                </a>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </li>
                                                        <li>
                                                            {association.Linkedin !=
                                                            null ? (
                                                                <a
                                                                    href={
                                                                        association.Linkedin
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <i className="icofont-linkedin"></i>
                                                                </a>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                )}
                            </div>
                        ))}
                    </OwlCarousel>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(Organisations);

import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel3";
import PlatinumSponsors from "../LaxButton/PlatinumSponsors";
import GoldSponsors from "../LaxButton/GoldSponsors";
import axios from "../../api/axios";
import { useParams } from "react-router-dom";
import React, { useState, useEffect ,useRef} from "react";
import ImageWithPlayIcon from "../Shared/ImageWithPlayIcon";
import ModalImage from "react-modal-image";
import LocalizedStrings from "react-localization";
import localeFile from "../../local/content";
import { connect } from "react-redux";
import ModalVideo from "react-modal-video";

const strings = new LocalizedStrings(localeFile);
function Organisationitem(props) {
    strings.setLanguage(props.languageCode);
    const [data, setData] = useState("");
    const [dataVideo, setdataVideo] = useState("");
    const [dataImage, setdataImage] = useState("");
    const [isOpen, setOpen] = useState(false);
    const [url, setUrl] = useState("");
    const [masterImage, setmasterImage] = useState("");
    const [modelIsOpen, setModalIsOpen] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`get_associations/${id}`);
                const data = response.data.association;

                setData(data);
                setdataImage(data.associaition_images);
                setdataVideo(data.associaition_videos);
    
                const masterImg = data.associaition_images.find((imag) => imag.master === 1);
                if (masterImg) {
                    setmasterImage(masterImg);
                }
            } catch (error) {
            }
        };
    
        fetchData();
    }, [id]);
    const videoRef = useRef(null);

    useEffect(() => {
      if (videoRef.current) {
        videoRef.current.currentTime = 40; // Réglez la position de lecture à 50 secondes
        videoRef.current.play(); // Démarrez la lecture
      }
    }, []);
    return (
        <React.Fragment>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId={url}
                onClose={() => setOpen(false)}
                loading={() => <div>{strings.Loading}</div>}
            />
            
            <div className="page-title-area  init">
                <div className="container">
                <video autoPlay loop muted className="video-background" ref={videoRef}>
      <source src="https://d2t3k1w51fob7.cloudfront.net/S%C3%A9quence%2003.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                    </video>
                    <h1>
                        {strings.hreflangCode === "fr"
                            ? data.nom
                            :strings.hreflangCode === "ar"? data.nom_arabe:data.nom_eng}
                    </h1>
                    <span>
                        {" "}
                        {strings.hreflangCode === "fr"
                            ? data.small_description
                            : strings.hreflangCode === "ar"?data.small_description_arabe:data.small_description_eng}
                    </span>
                </div>
            </div>

            <section className="partner-area ptb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="blog-details">
                                <div className="post-image">
                                    {dataImage.length > 0 &&
                                        dataImage.map((im, index) => {
                                        return (
                                               
                                                im.master == "1"?    <img  key={index}   src={ "https://dashboardawledna.futurevisions.tn/" + im.image     }  height="200px"  />:""
                                            );
                                        })}
                                </div>

                                <h3>
                                    {strings.hreflangCode === "fr"
                                        ? data.nom
                                        :strings.hreflangCode === "ar" ?data.nom_arabe:data.nom_eng}
                                </h3>

                                <div className="blog-meta">
                                    <ul>
                                        <li>
                                            <i className="icofont-location-pin"></i>
                                            {strings.hreflangCode === "fr"
                                                ? data.ville
                                                :strings.hreflangCode === "ar"? data.ville_arabe: data.ville_eng}
                                        </li>
                                    </ul>
                                </div>

                                <p>
                                    {strings.hreflangCode === "fr"
                                        ? data.description
                                        :strings.hreflangCode === "ar"? data.description_arabe:data.description_eng}
                                </p>

                                <blockquote className="blockquote">
                                    <p> {strings.hreflangCode === "fr"
                                        ? data.objectif
                                        :strings.hreflangCode === "ar"? data.objectif_arabe:data.objectif_eng}
                                    </p>
                                    
                                    <span class="blockquote-author">Nom de l'auteur</span>
                                </blockquote>

                            </div>

                            <div className="post-tag-media">
                                <div className="row h-100 align-items-center">
                                    <div className="col-lg-6"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="partner-title platinum-sponsor">
                                <PlatinumSponsors />
                            </div>
                        </div>

                        <OwlCarousel className="platinum-partner-slides owl-carousel owl-theme">
                            {dataImage.length !== 0 &&
                                dataImage.map((imag, index) => {
                                    return (
                                        <div className="col-lg-12 col-md-12 " key={index}>
                                            <div className=" m-3">
                                                <img
                                                    src={
                                                        "https://dashboardawledna.futurevisions.tn/" +
                                                        imag.image
                                                    }
                                                    alt=" Logo"
                                                    className="imgaeorgitem"
                                                    onClick={() => {
                                                        setModalIsOpen(true);
                                
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                        </OwlCarousel>

                        <div className="col-lg-12">
                            <div className="border"></div>
                        </div>

                        <div className="col-lg-12">
                            <div className="partner-title gold-sponsor">
                                <GoldSponsors />
                            </div>
                        </div>

                        <OwlCarousel className="gold-partner-slides owl-carousel owl-theme">
                            {dataVideo.length !== 0 &&
                                dataVideo.map((video, index) => {
                                    return (
                                        <div className="col-lg-12 col-md-12"  key={index}>
                                            <div className="m-3">
                                                <Link
                                                    to="#"
                                                    onClick={() => {
                                                        setOpen(true);
                                                        setUrl(video.url);
                                                    }}
                                                >
                                                    <ImageWithPlayIcon
                                                        src={
                                                            "https://dashboardawledna.futurevisions.tn/" +
                                                            video.image
                                                        }
                                                    />
                                                    <h4 className="widget-title text-center">
                                                        {video.title}
                                                    </h4>
                                                </Link>
                                            </div>
                                        </div>
                                    );
                                })}
                        </OwlCarousel>
                    </div>
                </div>
            </section>
     
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(Organisationitem);

import { Link } from "react-router-dom";
import Footer from "../Common/Footer";
import axios from "../../api/axios";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import SimpleDateTime from "react-simple-timestamp-to-date";
import { withTranslation } from "react-i18next";
import LocalizedStrings from "react-localization";
import localeFile from "../../local/content";

import { connect } from "react-redux";
import {
    LinkedinShareButton,
    FacebookShareButton,
    TwitterShareButton,
} from "react-share";
const strings = new LocalizedStrings(localeFile);
function BlogDetails(props) {
    strings.setLanguage(props.languageCode);

    const [data, setData] = useState("");
    const [categories, setcategories] = useState([]);
    const [posts, setposts] = useState([]);
    const [imagespost, setimagespost] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        axios
            .get(`get_post/${id}`)
            .then((res) => {
                const post = res.data.post;
                setData(post);
                setimagespost(post.post_images);
            })
            .catch(({ response }) => {
                // En cas d'erreur, vous pouvez gérer l'erreur ici
            });
    }, []);

    useEffect(() => {
        axios
            .get(`get_categories`)
            .then((res) => {
                setcategories(res.data.category);
            })
            .catch(({ response }) => {
                // En cas d'erreur, vous pouvez gérer l'erreur ici
            });
    }, []);
    useEffect(() => {
        axios
            .get(`get_posts`)
            .then((res) => {
                setposts(res.data.post);
            })
            .catch(({ response }) => {
                // En cas d'erreur, vous pouvez gérer l'erreur ici
            });
    }, []);

    return (
        <React.Fragment>
      

            <section className="blog-details-area ptb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="blog-details">
                                <h3 style={{color:"#333333"}}>
                                    {strings.hreflangCode == "fr"
                                        ? data.titre 
                                        : strings.hreflangCode == "ar" ?data.titre_arabe: data.titre_eng}
                                </h3>
                                {imagespost.length > 0 &&
                imagespost.map((im, index) => {
                    if (im.presentation == "1") {
                        return (
                            <div key={index}
                                className="page-title-area"
                                style={{  
                                    objectFit:"cover",
                                    backgroundImage:
                                        "url(" +
                                        "https://dashboardawledna.futurevisions.tn/" +
                                        im.image +
                                        ")",
                                    backgroundPosition: "center",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                }}
                            >
                               
                            </div>
                        );
                    }
                })}
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            strings.hreflangCode == "fr"
                                                ? data.body 
                                                :    strings.hreflangCode == "ar"? data.body_arabe: data.body_eng,
                                    }}
                                />
                            </div>

                            <div className="post-tag-media">
                                <div className="row h-100 align-items-center">
                                    <div className="col-lg-6"></div>

                                    <div className="col-lg-6">
                                        <ul className="social-share">
                                            <li>
                                                <span>
                                                    {" "}
                                                    {strings.PARTAGER_SUR}
                                                </span>
                                            </li>
                                            <li>
                                                <FacebookShareButton
                                                    url={`https://dashboardawledna.futurevisions.tn/single-blog/${id}`}
                                                >
                                                    <i className="icofont-facebook"></i>
                                                </FacebookShareButton>
                                            </li>
                                            <li>
                                                <TwitterShareButton
                                                    url={`https://dashboardawledna.futurevisions.tn/single-blog/${id}`}
                                                    title={data.titre}
                                                    hashtags={data.titre}
                                                >
                                                    <i className="icofont-twitter"></i>
                                                </TwitterShareButton>
                                            </li>

                                            <li>
                                                {" "}
                                                <LinkedinShareButton
                                                    url={`https://dashboardawledna.futurevisions.tn/single-blog/${id}`}
                                                    title={data.titre}
                                                >
                                                    <i className="icofont-linkedin"></i>
                                                </LinkedinShareButton>{" "}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="sidebar">
                                <div className="widget widget_categories">
                                    <h3 className="">{strings.Catégories}</h3>

                                    <ul>
                                        {categories.length != 0 &&
                                            categories.map(
                                                (category, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <Link
                                                                to={`/blog/id=${category.id}`}
                                                            >
                                                                {strings.hreflangCode ==
                                                                "fr"
                                                                    ? category.name
                                                                    : strings.hreflangCode ==
                                                                    "ar"?
                                                                    category.name_arabe:category.name_eng}
                                                            </Link>
                                                        </li>
                                                    );
                                                }
                                            )}
                                    </ul>
                                </div>

                                <div className="widget widget_recent_entries">
                                    <h3 className="">
                                        {strings.a_LIRE_AUSSI}{" "}
                                    </h3>

                                    <ul>
                                        {posts.length != 0 &&
                                            posts.map((post, index) => {
                                                return post.id != data.id ? (
                                                    <div key={index} style={{display:"flex"}}>
                                                        {post.post_images
                                                            .length > 0 &&
                                                            post.post_images.map(
                                                                (im, index) => {
                                                                    return (
                                                                    
                                                                          
                                                                            <img
                                                                            style={{width:"55px",height:"55px",objectFit:"contain", margin:"5px"}}
                                                                                src={
                                                                                    im.presentation ==
                                                                                    "1"
                                                                                        ? "https://dashboard.futurevisions.tn/" +
                                                                                          im.image
                                                                                        : ""
                                                                                }
                                                                                alt="blog"
                                                                            />
                                                                      
                                                                    );
                                                                }
                                                            )}

                                                     <div> 
                                                            <a
                                                                href={`/single-blog/id=${post.id}` } style={{color:"#333333"}}
                                                            >
                                                                {strings.hreflangCode ==
                                                                "fr"
                                                                    ? post.titre
                                                                    :strings.hreflangCode ==
                                                                    "ar" ?post.titre_arabe:post.titre_eng
                                                                    }
                                                            </a>
                                            
                                                        <span className="date">
                                                            <SimpleDateTime
                                                                dateFormat="DMY"
                                                                dateSeparator="/"
                                                                timeSeparator=":"
                                                            >
                                                                {
                                                                    post.updated_at
                                                                }
                                                            </SimpleDateTime>
                                                        </span></div> 
                                                    </div>
                                                ) : (
                                                    ""
                                                );
                                            })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(BlogDetails);

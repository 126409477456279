import React, { useEffect, useState,useRef } from 'react';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import axios from "../../api/axios";
import initializeEmailjs from "./emailjs_config.js";

import { connect } from 'react-redux';
import emailjs from '@emailjs/browser';
const strings = new LocalizedStrings(localeFile);

function Contact({ languageCode }) {
    useEffect(() => {
        initializeEmailjs();
      }, []);

      const videoRef = useRef(null);

      useEffect(() => {
        if (videoRef.current) {
          videoRef.current.currentTime = 45; // Réglez la position de lecture à 50 secondes
          videoRef.current.play(); // Démarrez la lecture
        }
      }, []);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formFields, setFormFields] = useState({
    from_name: '',
    from_email: '',
    phone: '',
    text: '',
    subject:''
  });
  const [social, setSocial] = useState({});

  useEffect(() => {
      axios.get(`/getSocial`).then((res) => {
          const socialData = res.data.social[0];
   
          setSocial(socialData);
      });
  }, []);


  const handleSubmit = (e) => {
  
    e.preventDefault();
    const form = e.target;
    setLoading(true)
    emailjs.sendForm('service_0u6aszj', 'template_ddf5blu', form, )
      .then((result) => {
        setLoading(false)
        setFormFields({
            from_name: '',
            from_email: '',
            phone: '',
            text: '',
            subject:''
          });

       
      }, (error) => {
      
      });
    

  }



  const onHideSuccess = () => {
    setSubmitted(false);
  };

  const successMessage = () => {
    if (submitted) {
      return (
        <div
          className="alert alert-success alert-dismissible fade show"
          style={{ marginTop: '14px' }}
        >
          <strong>{strings.merci}</strong> {strings.envoyermessage}
          <button
            type="button"
            className="close"
            onClick={onHideSuccess}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  };

  strings.setLanguage(languageCode);

  return (
    <React.Fragment>
      <div className="  contact-us   pt-5">
        <div className="container">

          <h3> {strings.CONTACTUS}</h3>
    
        </div>
      </div>

      <section className=" pb-5">
        <div className="container">
       

          <div className=" align-items-center contact-form">
            <div className="">
              <form onSubmit={handleSubmit} id="contactForm">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="from_name">{strings.Name}*</label>
                      <input
                        type="text"
                        className="form-control"
                        name="from_name"
                        id="from_name"
                        required={true}
                        data-error="Please enter your name"
                        value={formFields.from_name}
                        onChange={(e) => {
                            setFormFields({
                              ...formFields,
                              from_name: e.target.value,
                            });
                          }}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="from_email">{strings.Email}*</label>
                      <input
                        type="email"
                        className="form-control"
                        name="from_email"
                        id="from_email"
                        required={true}
                        data-error="Please enter your email"
                        value={formFields.from_email}
                        onChange={(e) => {
                            setFormFields({
                              ...formFields,
                              from_email: e.target.value,
                            });
                          }}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label htmlFor="number">{strings.Phone}*</label>
                      <input
                        type="text"
                        className="form-control"
                        name="number"
                        id="number"
                        required={true}
                        data-error="Please enter your number"
                        value={formFields.phone}
                        onChange={(e) => {
                            setFormFields({
                              ...formFields,
                              phone: e.target.value,
                            });
                          }}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label htmlFor="number">Objet*</label>
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        id="subject"
                        required={true}
                        data-error="Please enter your objet"
                        value={formFields.subject}
                        onChange={(e) => {
                            setFormFields({
                              ...formFields,
                              subject: e.target.value,
                            });
                          }}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label htmlFor="message">{strings.Message}*</label>
                      <textarea
                        name="message"
                        className="form-control"
                        id="message"
                        cols="30"
                        rows="4"
                        required={true}
                        data-error="Write your message"
                        value={formFields.text}
                        onChange={(e) => {
                            setFormFields({
                              ...formFields,
                              text: e.target.value,
                            });
                          }}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                  {loading?<div className='loader'></div>:<button type="submit" className="btn al">
                     {strings.envoyer}
                    </button>}     
                    
                    <div id="msgSubmit" className="h3 text-center hidden">
                      {successMessage()}
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </form>
            </div>
            <div className=''>
            <iframe  
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1618.8326142652647!2d10.599717710513962!3d35.7590322558612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fdf535b3aad113%3A0x2f3d487405989492!2zQXNzb2NpYXRpb24gRnV0dXJlIFZpc2lvbnMgLSDYrNmF2LnZitipINix2KTZiSDYp9mE2YXYs9iq2YLYqNmE!5e0!3m2!1sen!2stn!4v1697545410369!5m2!1sen!2stn" 
            
                     style={{border:"0" ,height:"39rem" ,width:"25rem" ,marginInline:'1rem'}}
                      allowfullscreen=""
                    loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="contact-box">
                <div className="icon">
                  <i className="icofont-phone"></i>
                </div>

                <div className="content">
                  <h4>{strings.phone}</h4>
                  <p dir='ltr' className='d-flex justify-content-center'>{social.numero_tel}</p>
                  <p dir='ltr' className='d-flex justify-content-center'>+216 73 284 003</p>

                  
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="contact-box">
                <div className="icon">
                  <i className="icofont-email"></i>
                </div>

                <div className="content">
                  <h4>{strings.Email}</h4>
                  <p>{social.email_address}</p>
               
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
              <div className="contact-box">
                <div className="icon">
                  <i className="icofont-world"></i>
                </div>

                <div className="content">
                  <h4>{strings.location}</h4>
                  <p>{social.adresse}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};

export default connect(mapStateToProps)(Contact);

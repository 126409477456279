import React, { useState, useEffect } from 'react';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { RxHamburgerMenu } from "react-icons/rx";

import LanguageSwitch from '../../LanguageSwitch/LanguageSwitch';
import logo from '../../assets/images/359674975_259010383191530_6932926900808888644_n-removebg-preview.png'
const strings = new LocalizedStrings(localeFile);

const NavBar = (props) => {
    const [collapsed, setCollapsed] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                // elementId.classList.add("is-sticky");
                window.history.pushState("", document.title, window.location.pathname);
            } else {
                // elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    }, []);

    const toggleOpen = () => setIsOpen(!isOpen);

    const onRouteChanged = () => {
        setIsOpen(false);
    }

    strings.setLanguage(props.languageCode);
    const classOne = collapsed ? 'collapse navbar-collapse ' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
    const menuClass = `dropdown-menu${isOpen ? " show" : ""}`;

    return (
        
              
        <div className=" ms-5 " >
        <nav class="navbar navbar-expand-lg navbar-light ">
            <a class="navbar-brand" href="#">  <img src={logo}/></a>

                   
                    <button
                        onClick={toggleNavbar}
                        className={classTwo}
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <label for="navbar-toggle" class="toggle-button">
                        <RxHamburgerMenu  />

                        </label>
                    </button>
                    <div className={classOne} id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto">
                    <li class="nav-item ">
                                <NavLink
                                    to="/home"
                                    className="nav-link">
                                    {strings.Accueil}
                                </NavLink>
                            </li>
                                    
                                        <li class="nav-item ">

                                <NavLink
                                    to="/organisations"
                                    className="nav-link"
                                >
                                    {strings.ORGANISATIONS}
                                </NavLink>
                            </li>
                                        <li class="nav-item ">

                                <NavLink
                                    to="/blog-1"
                                    className="nav-link"
                                >
                                    {strings.ACTUALITÉS}
                                </NavLink>
                            </li>
                                        <li class="nav-item ">

                                        <NavLink
                                    to="/contact"
                                    className="nav-link"
                                >
                                    {strings.CONTACTUS}
                                </NavLink>
                            </li>
                                      
                            
                            <li className=' nav-item nav-link' >
                            <NavLink
                                  to=""
                                    className="nav-link"
                                    >
                                <LanguageSwitch urlSuffix={['', '']} />    </NavLink>
                            </li>
                        </ul>
                      
                    
                    </div>
              
            </nav>
        </div>
       
    );
}

const mapStateToProps = state => {
    return {
        languageCode: state.languageCode
    }
}

export default connect(mapStateToProps)(NavBar);

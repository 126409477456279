import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faProjectDiagram, faUsers, faHandshake } from "@fortawesome/free-solid-svg-icons";

import LocalizedStrings from "react-localization";
import localeFile from "../../../local/content";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
const strings = new LocalizedStrings(localeFile);

const Features = (props) => {
    strings.setLanguage(props.languageCode);
    const [count, setCount] = useState(900);
    const [countproject, setCountproject] = useState(0);
    const [countpartner, setCountpartner] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (count < 1000) {
                setCount((prevCount) => prevCount + 1);
            } else {
                clearInterval(interval);
            }
        }, 20);

        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (countproject < 5) {
                setCountproject((prevCount) => prevCount + 1);
            } else {
                clearInterval(interval);
            }
        }, 150);

        return () => clearInterval(interval);
    }, [countproject]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (countpartner < 4) {
                setCountpartner((prevCount) => prevCount + 1);
            } else {
                clearInterval(interval);
            }
        }, 150);

        return () => clearInterval(interval);
    }, [countpartner]);

    return (
<section className="why-choose-us">
  <div className="why-choose-us-cent container">
  <div className="items-about">
        <div className="about-item ">
            <div className="item-icon">
          <FontAwesomeIcon icon={faProjectDiagram} className="icons"/></div>
          <span className="count"><span>1000</span></span>
          <span className="description">{strings.youthBen}</span>
        </div>
        <div className="about-item ">
        <div className="item-icon">
          <FontAwesomeIcon icon={faUsers}  className="icons"/>   </div>
          <span className="count"><span>+50</span></span>
          <span className="description">{strings.projetsBen}</span>
        </div>
        <div className="about-item ">
        <div className="item-icon">
          <FontAwesomeIcon icon={faHandshake} className="icons" />   </div>
          <span className="count"><span>100</span></span>
          <span className="description">{strings.partnersBen}</span>
       
      </div>   </div>
  </div>
</section>

    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(Features);
